/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DateDialog from '@/components/EdDate/Index';
import Group from 'vux/src/components/group';
import Cell from 'vux/src/components/cell';
import Checker from 'vux/src/components/checker/checker';
import CheckerItem from 'vux/src/components/checker/checker-item';
import CheckIcon from 'vux/src/components/check-icon';
import XButton from 'vux/src/components/x-button';
import Popup from 'vux/src/components/popup';
import PopupHeader from 'vux/src/components/popup-header';
export default {
  name: 'Attendance',
  data: function data() {
    return {
      showConfirm: false,
      endDate: '',
      currDate: '',
      currDates: '',
      ischeck: false,
      checkName: '',
      commonList: [],
      labelPosition: '',
      checklists: [],
      checklistslength: 0,
      checklistsnum: 0,
      thisIdx: 0,
      monthShow: false,
      weeksidx: 0,
      depList: [],
      userList: [],
      depChecked: [],
      userChecked: [],
      searchList: [],
      userSubList: [],
      depSubList: [],
      selectList: [],
      userInfo: [],
      userSum: 0,
      depSum: 0,
      allCheck: false,
      searchValue: '',
      searchFlag: false,
      detailFlag: false,
      showFlag: false,
      pagePath: [],
      initFlag: false,
      depSerial: this.$utils.Store.getItem('orgId'),
      //企业ID
      depName: this.$utils.Store.getItem('orgName') //企业名称

    };
  },
  components: {
    DateDialog: DateDialog,
    Cell: Cell,
    Group: Group,
    XButton: XButton,
    CheckIcon: CheckIcon,
    Checker: Checker,
    CheckerItem: CheckerItem,
    PopupHeader: PopupHeader,
    Popup: Popup
  },
  watch: {
    showFlag: function showFlag(val) {
      this.$emit('input', val);

      if (val) {
        var _this$userChecked;

        this.depList = [];
        this.userList = [];
        this.depChecked = [];
        this.userChecked = [];
        this.searchList = [];
        this.userSubList = [];
        this.depSubList = [];
        this.selectList = [];
        this.userInfo = [];
        this.allCheck = false;
        this.userSum = 0;
        this.depSum = 0;
        this.searchValue = '';
        this.searchFlag = false;
        this.detailFlag = false;
        this.pagePath = [];
        this.initFlag = false;

        (_this$userChecked = this.userChecked).push.apply(_this$userChecked, _toConsumableArray(this.infoChecked));

        this.userSum = this.userChecked.length;
        this.getOrgList(this.depSerial, this.depName, true);
      }
    },
    value: {
      handler: function handler(val) {
        this.showFlag = val;
      },
      immediate: true
    },
    allCheck: function allCheck() {}
  },
  computed: {
    bodyBottom: function bodyBottom() {
      return this.selectType !== 1 ? '0.41rem' : '0.41rem';
    },
    btnBottom: function btnBottom() {
      return this.selectType !== 1 ? 'padding-bottom:0.065rem' : 'padding-bottom:0.355rem';
    },
    depCheck: function depCheck() {
      return function (obj) {
        var temp = this.depChecked.filter(function (item) {
          return item === obj;
        });
        return temp.length > 0;
      };
    },
    userCheck: function userCheck() {
      return function (obj) {
        var temp = this.userChecked.filter(function (item) {
          return item === obj;
        });
        return temp.length > 0;
      };
    }
  },
  mounted: function mounted() {
    var thisDate = this.$utils.DateUtils.getYYYYMMDD(new Date());
    var kong = this.$utils.DateUtils.getWeekTimeStamp(thisDate);
    this.currDate = this.$utils.DateUtils.lastMonday(thisDate, kong);
    this.currDates = this.$utils.DateUtils.lastMonday(thisDate, kong);
    this.endDate = this.$utils.DateUtils.lastsunday(thisDate, kong);
    this.getOrgList(this.depSerial, this.depName, true);
  },
  methods: {
    delOrg: function delOrg(obj) {
      var index = this.depChecked.indexOf(obj);

      if (index > -1) {
        this.depChecked.splice(index, 1);
      }
    },
    delUser: function delUser(obj) {
      var index = this.userChecked.indexOf(obj);

      if (index > -1) {
        this.userChecked.splice(index, 1);
      }

      this.userInfo = this.userInfo.filter(function (user) {
        return user.userSerial !== obj;
      });
    },
    userSelect: function userSelect() {
      var _this = this;

      this.userInfo = [];

      if (this.userChecked.length > 0) {
        this.$utils.Tools.loadShow();
        this.$api.Org.getUserInfo(JSON.stringify(this.userChecked)).then(function (response) {
          _this.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg,
              data = response.data;

          if (+code !== _this.$code.success) {
            _this.$utils.Tools.toastShow(msg);
          } else {
            _this.userInfo = data;
          }
        }).catch(function () {
          _this.$utils.Tools.httpError();
        });
      }
    },
    onDetail: function onDetail() {
      // if (this.userSum + this.depSum === 0) {
      //   return
      // }
      // this.userSelect()
      this.detailFlag = true;
    },
    allChecks: function allChecks(check) {
      var _this2 = this;

      this.allCheck = !check;

      if (this.allCheck) {
        if (this.depList.length > 0) {
          this.depList.filter(function (dep) {
            if (_this2.depChecked.indexOf(dep.depSerial) === -1) {
              _this2.depChecked.push(dep.depSerial);
            }
          });
        }

        if (this.userList.length > 0) {
          this.userList.filter(function (user) {
            if (_this2.userChecked.indexOf(user.userSerial) === -1) {
              _this2.userChecked.push(user.userSerial);
            }
          });
        }
      } else {
        if (this.depList.length > 0) {
          this.depList.filter(function (dep) {
            for (var index in _this2.depChecked) {
              if (_this2.depChecked[index] === dep.depSerial) {
                _this2.depChecked.splice(index, 1);
              }
            }
          });
        }

        if (this.userList.length > 0) {
          this.userList.filter(function (user) {
            for (var index in _this2.userChecked) {
              if (_this2.userChecked[index] === user.userSerial) {
                _this2.userChecked.splice(index, 1);
              }
            }
          });
        }
      }
    },
    onJump: function onJump(dep) {
      this.getOrgList(dep.depSerial, dep.depName, true);
    },
    onDepCheck: function onDepCheck() {
      var _this3 = this;

      if (this.selectType === 1) {
        return;
      }

      var depTemp = this.depList.filter(function (dep) {
        return _this3.depChecked.indexOf(dep.depSerial) > -1;
      });
      depTemp.filter(function (item) {
        var obj = JSON.stringify({
          depSerial: item.depSerial,
          depName: item.depName,
          userNums: item.userNums
        });

        var index = _this3.depSubList.indexOf(obj);

        if (index > -1) {
          _this3.depSubList.splice(index, 1);
        }

        _this3.depSubList.push(obj);
      });
      this.depSubList = this.depSubList.filter(function (item) {
        var obj = JSON.parse(item);
        return _this3.depChecked.indexOf(obj.depSerial) > -1;
      });
      this.depSum = 0;
      this.depSubList.filter(function (item) {
        var obj = JSON.parse(item);
        _this3.depSum = _this3.depSum + obj.userNums;
      });
    },
    getOrgList: function getOrgList(depSerial, depName, flag) {
      var _this4 = this;

      this.$utils.Tools.loadShow();
      this.$api.Attence.getSelectDepInfo({
        depSerial: depSerial,
        data: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        _this4.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this4.$code.success) {
          _this4.$utils.Tools.toastShow(msg);
        } else {
          if (flag) {
            var dep = {
              depSerial: depSerial,
              depName: depName
            };

            _this4.pagePath.push(dep);
          } else {
            var _flag = false;
            var _index = 0;

            for (var index in _this4.pagePath) {
              _index = index;

              if (_this4.pagePath[index].depSerial === depSerial) {
                _flag = true;
                break;
              }
            }

            if (_flag) {
              _this4.pagePath.splice(+_index + 1, _this4.pagePath.length - _index - 1);
            }
          }

          _this4.depList = data.depList;
          _this4.userList = [];
          _this4.initFlag = true;
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },
    weeks: function weeks(i) {
      this.weeksidx = i;

      if (i == 1) {
        this.monthShow = true;
      } else {
        this.monthShow = false;
      }

      this.setDate(i, 1);
    },
    setDate: function setDate(idx, i) {
      if (this.weeksidx == 0) {
        var thisDate = '';

        if (i != 1) {
          if (idx == 1) {
            thisDate = this.$utils.DateUtils.beforesunday(this.currDate, -7);
          } else {
            thisDate = this.$utils.DateUtils.beforesunday(this.currDate, 7);
          }
        } else {
          thisDate = this.currDate;
        }

        var kong = this.$utils.DateUtils.getWeekTimeStamp(thisDate);
        this.currDate = this.$utils.DateUtils.lastMonday(thisDate, kong);
        this.endDate = this.$utils.DateUtils.lastsunday(thisDate, kong);
      } else {
        if (i != 1) {
          if (idx == 1) {
            this.dateidx--;
            this.currDates = this.$utils.DateUtils.getonMonth(this.currDates);
          } else {
            this.dateidx++;
            this.currDates = this.$utils.DateUtils.gettheMonth(this.currDates);
          }
        }

        this.currDates = this.$utils.DateUtils.getYYYYMM(this.currDates);
      }
    },
    onConfirm: function onConfirm(ref) {
      ref = this.$utils.Tools.repairDate(ref);
      if (ref == '') return;
      this.currDate = ref;
      this.setDate(this.weeksidx, 1);
    },
    selectFirstTwo: function selectFirstTwo() {
      if (this.depChecked.length === 0) {
        this.$utils.Tools.toastShow('至少选择一个部门');
        return;
      }

      this.$router.push({
        path: '/AttendanceIndex',
        query: {
          list: this.depChecked.toString(),
          checklistsnum: this.userSum + this.depSum,
          checklistnum: this.depChecked.length,
          currDate: this.currDate,
          endDate: this.endDate,
          currDates: this.currDates,
          weeksidx: this.weeksidx
        }
      });
    },
    ischecks: function ischecks() {
      var _this5 = this;

      this.checklists = [];
      if (!this.ischeck) return;
      this.commonList.map(function (item) {
        _this5.checklists.push(item.key);
      });
    },
    change: function change() {
      var _this6 = this;

      if (this.checklists.length === this.commonList.length) {
        this.ischeck = true;
      } else {
        this.ischeck = false;
      }

      if (this.checklists.length === 0) {
        this.ischeck = false;
      }

      var num = 0;
      this.commonList.map(function (item) {
        if (_this6.checklists.includes(item.key)) {
          num += item.userNums;
        } else {
          _this6.checklistsnum -= item.userNums;
        }
      });
      this.checklistsnum = num;
    }
  }
};